/**
 * @module SalesFlow/view
 */

import AtomicDevice from 'model/type/atomic-device';

import ViewColors from 'view/view/shared/picker/colors';
import ViewSizes from 'view/view/shared/picker/sizes';
import ViewBundles from 'view/view/shared/picker/bundles';

import SimOnlyOffer from './sim-only-offer';
import ViewAccessoryOffer from '../accessory-offer';

import Subscription from 'model/type/subscription';
import VluxOffer from 'model/type/offer';

/**
 * @TODO To me clear in class naming: This should be named and imported as FrontendDeviceOffer
 */
/**
 * @internal
 */
export default class DeviceOffer extends SimOnlyOffer {

    public readonly type: string = 'device';

    protected _atomicDevice: AtomicDevice;

    protected _accessorieOffers: ViewAccessoryOffer[];

    protected _isDeviceSelected: boolean = false;

    protected _onetimeDeviceStrikePrice: number;

    constructor (
        atomicDevice: AtomicDevice,
        subscription: Subscription,
        offer: VluxOffer,
        redPlusOffers: DeviceOffer[] = [],
        optionalServices: number[] = [],
        accessoryOffers: ViewAccessoryOffer[] = []
    ) {

        super(subscription, offer, redPlusOffers, optionalServices);

        this._atomicDevice = atomicDevice;

        this._accessorieOffers = accessoryOffers;

        /**
         * sets cumulated onetime Price for complete offer
         */
        this.setOnetimePrice();

    }

    protected setOnetimePrice (): void {

        this._onetimePrice = 0;

        if ('HoK' !== this._offer.offerType) {
            this._connectionFee += this._offerTariffCost.connectionFee;
        }

        /**
         * Red+ Costs
         */
        for (const redPlusOffer of this._redPlusOffers) {

            if ('HoK' !== this._offer.offerType) {
                this._connectionFee += redPlusOffer._offerTariffCost.connectionFee;
            }

        }

        this._onetimePrice += this._offer.devicePrice[0].value;

    }

    public setIsDeviceSelected (isSelected: boolean): void {
        this._isDeviceSelected = isSelected;
    }

    /**
     *  Device reduced onetime price
     */
    get onetimeDevicePrice (): number {
        return this._offer.devicePrice[0].value;
    }

    /**
     *  Device regular onetime strike price
     */
    public setOnetimeDeviceStrikePrice (stikePrice: number) {
        this._onetimeDeviceStrikePrice = stikePrice;
    }

    get onetimeDeviceStrikePrice (): number {
        return this._onetimeDeviceStrikePrice;
    }

    /**
     * Cumulated Onetime Price inluding connection Fee
     */
    get onetimePrice (): number {

        /**
         *    Device Price
         *  + Connection Fee
         *  + Accessory
         *  + Red+ Connection Fee
         *  + Red+ Device price
         */

        let cumulatedCosts: number = this._onetimePrice + this._connectionFee;

        for (const accessoryOffer of this._accessorieOffers) {

            cumulatedCosts += accessoryOffer.onetimeAccessoryPrice;

        }

        for (const redplusOffer of this.redPlusOffers) {

            if ('HmK' === redplusOffer.offer.offerType) {
                cumulatedCosts += redplusOffer.onetimeDevicePrice;
            }

            /**
             * connectionFee for redPlus already included in this._connectionFee
             */

        }

        return cumulatedCosts;
    }

    /**
     * Cumulated Onetime Price excluding connection Fee
     */
    get onetimePriceWithoutConnectionFee (): number {

        /**
         *    Device Price
         *  + Connection Fee
         *  + Accessory
         *  + Red+ Connection Fee
         *  + Red+ Device price
         */

        const cumulatedCosts: number = this.onetimePrice;

        return cumulatedCosts - this._connectionFee;

    }

    get atomicDevice (): AtomicDevice {
        return this._atomicDevice;
    }

    get atomicDeviceId (): number {
        return this._atomicDevice.id;
    }

    get attrDeviceId (): string {
        return 'data-device-id="' + this._atomicDevice.device.id + '"';
    }

    get attrAtomicDeviceId (): string {
        return 'data-atomic-device-id="' + this._atomicDevice.id + '"';
    }

    get attrVendor (): string {
        return 'data-vendor="' + this._atomicDevice.attr.vendor + '"';
    }

    get attrProductType (): string {
        return 'data-product-type="' + this._atomicDevice.attr.productType + '"';
    }

    get attrPromotion (): string {
        const promotions: string[] = [];

        return 'data-promotion=\"' + promotions.join(',') + '\"';

    }

    get attrCharacteristic (): string {

        const characteristics: string[] = [];

        /*
        for (let characteristic of this.device.characteristic) {
            characteristics.push(characteristic);
        }

        if (0 === characteristics.length) {
            return '';
        }
        */

        return 'data-characteristic=\"' + characteristics.join(',') + '\"';

    }

    get dataAttr (): string {

        const attrs: string[] = [];

        attrs.push(this.attrDeviceId);
        attrs.push(this.attrAtomicDeviceId);
        attrs.push(this.attrVendor);
        attrs.push(this.attrPromotion);
        attrs.push(this.attrCharacteristic);
        attrs.push(this.attrProductType);

        return attrs.join(' ');

    }

    get isDeviceSelected (): boolean {

        return this._isDeviceSelected;

    }

    get deviceSelected (): string {

        if (false === this._isDeviceSelected) {
            return '';
        }

        return 'selected';
    }

    get deviceInsuranceSelected (): string {

        if (this.offer.hasHandyInsuranceIncluded()) {
            return 'fixed';
        }

        if (0 <= this._optionalServices.indexOf(158) || 0 <= this._optionalServices.indexOf(159)) {
            return 'selected';
        }

        return '';
    }

    get deviceInsurancePrice (): number {
        return this.offer.getHandyInsurancePrice();
    }

    get nsfImage90_120 (): string {
        return 'https://opweb5.vfd2-testnet.de' + this._atomicDevice.attr.nsfImage90_120;
    }

    get nfsLargeImages (): string[] {

        const imgArray = this._atomicDevice.attr.nsfImage288_320.map(
            (imgUrl: string) => {
                return 'https://opweb5.vfd2-testnet.de' + imgUrl;
            }
        );

        return imgArray;

    }

    get name (): string {
        return this._atomicDevice.name;
    }

    get deviceName (): string {
        return this._atomicDevice.getDevice().name;
    }

    get vendor (): string {
        return this._atomicDevice.attr.vendor;
    }

    get topFeatures (): string[] {
        return this._atomicDevice.attr.topFeatures;
    }

    get deliveryTime (): string {
        return this.atomicDevice.attr.deliveryTime;
    }

    /**
     * in case an empty string is returned, no stopper will be displayed
     */
    get stopperImage (): string {
        return '/simplicity/assets/img/new-sales-flow/gigaboost-120x120.png';
        // return '';
    }

    /**
     * this text will be added to tariff detail overlay
     */
    get stopperAdditionalText (): string {
        return 'lorem ipsum sid dolor';
        // return '';
    }

    get colors (): ViewColors {
        return new ViewColors(this.atomicDevice);
    }

    get sizes (): ViewSizes {
        return new ViewSizes(this.atomicDevice);
    }

    get bundles (): ViewBundles {
        return new ViewBundles(this.atomicDevice);
    }

    /**
     * @TODO accessories
     */
    get accessories (): any[] {
        return this._accessorieOffers;
    }

    /**
     * headline above "Dein gewähltes Paket": different in sim-only offer and divice-offer
     */
    get labelMainProposition (): string {

        if ('HoK' === this._offer.offerType) {
            return this._atomicDevice.device.name;
        } else {
            return this._subscription.name + ' mit ' + this._atomicDevice.device.name;
        }

    }

    /**
     * Headline in pricebox. In this case logic out of template, because of further expansions (Voice / Data)
     */
    get labelHeaderOnetime (): string {

        let count = 1;

        if (0 < this._accessorieOffers.length) {
            count++;
        }

        if (0 < this._redPlusOffers.length && 'number' === typeof this._redPlusOffers[0].atomicDeviceId) {
            count++;
        }

        return 'Einmalpreis <div class="package only-lrg">f&uuml;r ' + (1 < count ? 'Deine Ger&auml;te' : 'Dein Smartphone oder Tablet') + ' </div>';
    }

    // @TODO Maybe this is not needed to be exposed in production
    get attr (): any {
        return this._atomicDevice.attr;
    }

    get isMarkedAsLowestOffer (): boolean {
        return this._offer.isMarkedAsLowestOffer;
    }

}
