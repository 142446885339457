/**
 * @module SalesFlow/core
 */

import {GigakombiVorteilName} from 'core/ids';
import Cookie from 'router/cookie';
import GetParameter from 'router/get-parameter';
import Options from 'core/options';
import Storage from 'core/storage';
import {Constants} from 'core/constants';

/**
 * @TODO Usage of Cookie an Sessions Storage is redundant. Delete Storage while Cookie is used in checkout
 */
export default class Gigakombi {

    private _gigakombiVorteil: GigakombiVorteilName = undefined;
    private _isGigakombi: boolean = undefined;
    private _urlParam: string = undefined;
    private _apiUrl: string = '/api/vfd2dyn/json/gigakombi-crypt/serviceaio.php';
    private _eeccToken: string = 'eeccGkToken';
    protected myCookie = new Cookie();
    protected getParameter = new GetParameter();
    protected _options: Options;
    protected _storage: Storage;

    public constructor (storage: Storage, getParameter: GetParameter, options: Options) {

        this._storage = storage;
        this._options = options;

        const fromStorage = this._storage.getItem(Constants.BTX_GIGAKOMBI);
        if (null !== fromStorage) {
            if ('IP' === fromStorage) {
                this.setVorteil(fromStorage);
            }
            else if ('TV' === fromStorage) {
                this.setVorteil(fromStorage);
            }
            else if ('BR5' === fromStorage) {
                this.setVorteil(fromStorage);
            }
            else if ('IPandTV' === fromStorage) {
                this.setVorteil(fromStorage);
            }
            else if ('IPandBR5' === fromStorage) {
                this.setVorteil(fromStorage);
            }
            else if ('Unity' === fromStorage) {
                this.setVorteil(fromStorage);
            }
        }

    }

    public is (): boolean {
        return (undefined !== this._gigakombiVorteil);
    }

    public isGigakombi (): boolean {
        const backUrl = this.getParameter.getLocationSearchParameter('backUrl');
        const token = this.getParameter.getLocationSearchParameter('token');
        const umCase = this.getParameter.getLocationSearchParameter('umCase');
        const cookiedata = this.readCookie();
        const page = $('#nsf-dispatcher').data('page');

        if (backUrl || token || umCase) {
            this._isGigakombi = true;
        } else {
            if ('string' === typeof cookiedata.URL || 'string' === typeof cookiedata.token || 'string' === typeof cookiedata.umCase) {
                this._isGigakombi = true;
            } else {
                this._isGigakombi = false;
            }
        }

        return this._isGigakombi;
    }

    public setVorteil (gigakombiVorteilName: GigakombiVorteilName) {
        this._gigakombiVorteil = gigakombiVorteilName;
        console.log('Gigakombi: ' + this._gigakombiVorteil);
    }

    public getVorteil (): GigakombiVorteilName {

        return this._gigakombiVorteil;

    }

    public checkGigakombi (): JQueryPromise<any> {

        const deferred = $.Deferred<any>();
        const backUrl = this.getParameter.getLocationSearchParameter('backUrl');
        const token = this.getParameter.getLocationSearchParameter('token');
        const umCase = this.getParameter.getLocationSearchParameter('umCase');
        const ul_u = this.getParameter.getLocationSearchParameter('ul_u');

        const page = $('#nsf-dispatcher').data('page');

        /**
         * regular Gigakombi Flow
         */

        if (backUrl) {
            this.checkParameter(backUrl).then(() => {
                this._urlParam = 'backUrl=' + encodeURI(backUrl);
                deferred.resolve();
            });
        } else if (token) {
            this.checkToken(token).then(() => {
                this._urlParam = 'token=' + encodeURIComponent(token);
                deferred.resolve();
            });
        } else if (umCase) {
            this.checkUnityMediaCase(umCase).then(() => {
                this._urlParam = 'umCase=' + encodeURI(umCase);
                deferred.resolve();
            });
        } else {
            // no initial params, check if cookie with data is set
            this.checkCookie().then((what) => {
                this._urlParam = what + '=' + this.readCookie();
                deferred.resolve();
            });
        }

        return deferred.promise();
    }

    public prepareDiscounts (useCases: Array<string> = []) {
        let withIPDiscount = false;
        if (0 <= useCases.indexOf('IP')) {
            this.setVorteil('IP');
            withIPDiscount = true;
        }
        if (0 <= useCases.indexOf('Unity')) {
            this.setVorteil('Unity');
        }
        if (0 <= useCases.indexOf('BR5')) {
            if (true === withIPDiscount) {
                this.setVorteil('IPandBR5');
            } else {
                this.setVorteil('BR5');
            }
        } else if (0 <= useCases.indexOf('TV')) {
            if (true === withIPDiscount) {
                this.setVorteil('IPandTV');
            } else {
                this.setVorteil('TV');
            }
        }

        this._storage.setItem(Constants.BTX_GIGAKOMBI, this._gigakombiVorteil);
    }

    public checkCampaign (ul_u: string): JQueryPromise<any> {
        const deferred = $.Deferred<any>();
        if ('FNF_0023' === ul_u) {
            this.setVorteil('IP');
            this._storage.setItem(Constants.BTX_GIGAKOMBI, this._gigakombiVorteil);
            deferred.resolve();
        } else {
            this.processNotElegible();
        }

        return deferred.promise();
    }

    public checkParameter (backUrl: string): JQueryPromise<any> {
        const deferred = $.Deferred<any>();
        if (0 <= backUrl.indexOf('vi2opdsl5.vfd2-testnet.de') || 0 <= backUrl.indexOf('arcor.de')) {
            this.setVorteil('IP');
            const data: any = {};
            const encodedBackUrl = encodeURIComponent(backUrl);
            data.URL = encodedBackUrl;
            this.saveCookie(data);
            this.callGkService({
                dslbackurl: encodedBackUrl
            }).then((response: any) => {
                this.saveEECCCustomerInformation(response.customerInformation);
            });
            this._storage.setItem(Constants.BTX_GIGAKOMBI, this._gigakombiVorteil);
            deferred.resolve();
        } else {
            this.processNotElegible();
        }

        return deferred.promise();
    }

    public checkUnityMediaCase (umCase: string): JQueryPromise<any> {
        const deferred = $.Deferred<any>();
        if (0 <= umCase.indexOf('hw')) {
            this.setVorteil('Unity');
            const data: any = {};
            data.umCase = encodeURIComponent(umCase);
            this.saveCookie(data);
            this._storage.setItem(Constants.BTX_GIGAKOMBI, this._gigakombiVorteil);
            deferred.resolve();
        } else {
            this.processNotElegible();
        }

        return deferred.promise();
    }

    public processNotElegible () {
        this.myCookie.remove('msredone');
        window.location.href = this._options.get('gigakombi_not_elegible_url');
    }

    public checkToken (token: string): JQueryPromise<any> {
        const deferred = $.Deferred<any>();

        const data = {
            kdgCipher: token,
            olsOrderId: 'check'
        };

        this.callGkService(data).then((response: any) => {
            if (response && 'undefined' !== typeof response.result && 'ok' === response.result) {

                if ('object' === typeof response.uc) {
                    const data: any = {};
                    data.token = encodeURIComponent(token);
                    this.saveCookie(data);
                    this.saveEECCCustomerInformation(response.customerInformation);
                    this.prepareDiscounts(response.uc);
                    deferred.resolve();
                }
            } else {
                this.processNotElegible();
            }
        });

        return deferred.promise();
    }

    public callGkService (data: any): JQueryPromise<any> {
        const deferred = $.Deferred<any>();

        $.ajax({
            url: this._apiUrl,
            method: 'get',
            dataType: 'json',
            data: data
        }).then(function (success) {
            deferred.resolve(success);
        }, function (error) {
            deferred.reject(error);
        });

        return deferred.promise();
    }

    public checkCookie (): JQueryPromise<any> {
        const deferred = $.Deferred<any>();
        const cookiedata = this.readCookie();
        if ('string' === typeof cookiedata.URL) {
            this.checkParameter(decodeURIComponent(cookiedata.URL)).then(() => {
                deferred.resolve('backUrl');
            });
        } else if ('string' === typeof cookiedata.token) {
            this.checkToken(decodeURIComponent(cookiedata.token)).then(() => {
                deferred.resolve('token');
            });
        } else if ('string' === typeof cookiedata.umCase) {
            this.checkUnityMediaCase(decodeURIComponent(cookiedata.umCase)).then(() => {
                deferred.resolve('umCase');
            });
        } else {
            this.processNotElegible();
        }

        return deferred.promise();
    }

    public readCookie () {
        let value = this.myCookie.get('msredone');

        if (false === this.checkIsJson(value)) {
            value = decodeURIComponent(value);
        }

        return value ? JSON.parse(value) : {};
    }

    public saveCookie (data: any) {
        data = ('string' === typeof data) ? data : JSON.stringify(data);
        this.myCookie.write('msredone', data, 1);
    }

    public saveEECCCustomerInformation (token: string) {
        this.myCookie.write(this._eeccToken, token, 1);
    }

    public checkIsJson (json: any) {
        try {
            JSON.parse(json);
        } catch (e) {
            return false;
        }

        return true;
    }

    public getUrlParam (): string {
        return this._urlParam;
    }
}
